@font-face {
  font-family: "Bebas Neue Regular";
  src: url(./../fonts/BebasNeueRegular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "SallenasGrande Regular";
  src: url(./../fonts/SallenasGrandesRegular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Regular";
  src: url(./../fonts/MontserratRegular.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat Light";
  src: url(./../fonts/MontserratLight.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Bold";
  src: url(./../fonts/MontserratBold.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans Regular";
  src: url(./../fonts/OpenSansRegular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Open Sans Italic";
  src: url(./../fonts/OpenSansItalic.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Black";
  src: url(./../fonts/Roboto-Black.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Bold";
  src: url(./../fonts/Roboto-Bold.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Thin";
  src: url(./../fonts/Roboto-Thin.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Medium";
  src: url(./../fonts/Roboto-Medium.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Roboto Regular";
  src: url(./../fonts/Roboto-Regular.ttf);
  font-weight: normal;
}

$colors: (
  greenMontan: #00739e,

  mainlyBlack: black,
  chillBlack: gray,
  redBomb: #00ac10,
  headColor: #caa987,
  headColorLight: #e4be98,
  welcomeHead: #5c534c,
  elementText: #524033,
  borderAbout: rgb(83, 85, 0),
  whiteCreamy: #fcebda,
  whiteCreamyHover: #f3efea,
  navBarBackground: rgba(233, 233, 233, 0.8),
  navBarBackgroundMobile: rgba(116, 51, 51, 0),
  burgerColorBackground: white,
  liItemColor: #caa987,
  whitePure: rgb(255, 255, 255),
  whiteSmokey: whitesmoke,
  chillGrayTransparent: rgba(95, 95, 95, 0.41),
  chillGraySolid: rgb(95, 95, 95),
  blueSky: rgb(4, 126, 182),
  silver: rgb(169, 169, 169),
  beautyBush: #eeb9cb,
  blackSolid: black,
  errorRed: #ff0000
);
