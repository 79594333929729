@font-face {
  font-family: "SallenasGrande Regular";
  src: url(fonts/SallenasGrandesRegular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Regular";
  src: url(fonts/MontserratRegular.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat Light";
  src: url(fonts/MontserratLight.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat Bold";
  src: url(fonts/MontserratBold.ttf);
  font-weight: 400;
}

.App {
  /* max-width: 1920px; */
  /* max-width: 4000px; */
  /* margin: auto; */
  /* position: relative; */
  overflow-x: hidden;
  /* width: 100vw;
  height: 100vh;
  overflow-y: scroll; */
}
